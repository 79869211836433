<template>
	<Loader />
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '@/shared/loader/Loader.vue';

export default {
	name: 'LogoutScreen',
	components: {
		Loader,
	},
	mounted() {
    this.logout();
  },
	methods: {
		...mapActions(['logout']),
	},
};
</script>

<style scoped lang="scss">
</style>
